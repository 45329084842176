/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useLocation, Routes, Route  } from "react-router-dom";
import './style/index.scss';
import './style/main.scss';
import { useCookies } from 'react-cookie';
import { initHandler } from './store';
import { Cities } from './page/Cities';
import { Menu } from './page/MainMenu';
import { Filters } from './page/FiltersPage/index';
import { Android } from './page/Android';
import { Places } from './page/PlacePage';
import PlaceSearchPage from './page/PlacePage/PlaceSearchPage';
import PlaceCategoryPage from './page/PlacePage/PlaceCategoryPage';
import NewPlacesPage from './page/PlacePage/NewPlacesPage';
import NearPlacesPage from './page/PlacePage/NearPlacesPage';
import WishList from './page/PlacePage/WishList';
import ClubPlacePage from './page/PlacePage/ClubPlacePage';
import PlaceDetailPage from './page/PlacePage/PlaceDetailPage';

const _App = () => {
    const tg = window.Telegram.WebApp;
    // eslint-disable-next-line no-unused-vars
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [cookies, setCookies] = useCookies(['query', 'auth_date', 'hash']);
    // eslint-disable-next-line no-unused-vars
    const [chosenCity, setChosenCity] = React.useState(localStorage.getItem('city'));

    useEffect(() => {
        setCookies('query', tg.initData ? tg.initData : '');
        setCookies(
            'auth_date',
            tg.initDataUnsafe.auth_date ? tg.initDataUnsafe.auth_date : '',
        );
        setCookies(
            'hash',
            tg.initDataUnsafe.hash ? tg.initDataUnsafe.hash : '',
        );
        initHandler();
    }, []);

    useEffect(() => {
        // TODO: add localStorage check city exist;
    }, [location])

    return (
        <Routes>
            <Route path="/" element={<Cities />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/filters" element={<Filters />} />
            <Route path="/places" element={<Places />} />
            <Route
                path="/places/category/:categoryKey"
                element={<PlaceCategoryPage />}
            />
            <Route path="/places/club" element={<ClubPlacePage />} />
            <Route path="/places/new" element={<NewPlacesPage />} />
            <Route path="/places/near" element={<NearPlacesPage />} />
            <Route path="/places/search" element={<PlaceSearchPage />} />
            <Route path="/places/wishlist" element={<WishList />} />
            <Route path="/places/:id" element={<PlaceDetailPage />} />
            <Route path="/call" element={<Android />} />
        </Routes>
    );
};

export const App = React.memo(_App);
