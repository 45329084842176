/* eslint-disable default-param-last */
const defaultState = [];

// action = {type: string, payload: any}
export const categoiesReducer = (state = defaultState, action) => {
    switch (action.type) {
    case 'initCategories':
        return [...action.payload];
    default:
        return state;
    }
};
