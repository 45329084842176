import React from 'react';
import './cities.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SpbIcon from '../../icon/cities/Spb.png';
import MscIcon from '../../icon/cities/Msc.png';
import KznIcon from '../../icon/cities/Kzn.png';
import EkbIcon from '../../icon/cities/Ekb.png';
import aclub from '../../icon/cities/witha.svg';
import { ICity } from '../../interfaces/interfaces';

const _Cities = () => {
    const citiesList: ICity[] = useSelector((state: any) => state.cities);
    const navigator = useNavigate();
    // @ts-ignore
    // const tg = window.Telegram.WebApp;
    const cityIconMap = [
        { key: 1, icon: MscIcon },
        { key: 2, icon: SpbIcon },
        { key: 3, icon: KznIcon },
        { key: 4, icon: EkbIcon },
    ];

    React.useEffect(() => {
        const savedCity = localStorage.getItem('city');
        if (savedCity && JSON.parse(savedCity)?.id) {
            navigator('/menu');
        }
    }, [])

    const queryParamHandler = (e: React.MouseEvent) => {
        localStorage.setItem('city', JSON.stringify({
            id: (e.target as any).id,
            title: (e.target as any).dataset.title,
        }));
        navigator('/menu');
    };
    const getIcon = (key: number) => {
        const city = cityIconMap.filter(el => el.key === +key)
        return city[0].icon
    }

    return (
        <div className="cities">
            <link rel="stylesheet" href="" />
            <h1 className="cities__title">
                В каком городе будем искать заведение?
            </h1>
            <div className="cities__list">
                {citiesList.map((city: any) => (
                    <button
                        type='button'
                        className="cities__item"
                        id={city.key}
                        data-title={city.title}
                        key={city.key}
                        onClick={queryParamHandler}
                    >
                        <img
                            className="cities__icon"
                            id={city.key}
                            data-title={city.title}
                            src={getIcon(city.key)}
                            alt='city icon'
                        />
                        <div
                            id={city.key}
                            data-title={city.title}
                            className="cities__city-title"
                        >
                            {city.title}
                        </div>
                    </button>
                ))}
            </div>
            <div className="cities-aClub">
                <img src={aclub} alt='alfa club icon'/>
            </div>
        </div>
    );
};

export const Cities = React.memo(_Cities);
