import React from 'react';
import './menu.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuHeader from '../../components/Header/MenuHeader';
import fineDiningIcon from '../../icon/menu/FineDining.svg';
import smartCasual from '../../icon/menu/SmartCasual.svg';
import cityCafe from '../../icon/menu/CityCafe.svg';
import bars from '../../icon/menu/Bars.svg';
import settings from '../../icon/menu/settings.svg';
import aClub from '../../icon/menu/aclub.svg';
import pointer from '../../icon/menu/pointer.svg';
import newIcon from '../../icon/menu/new.svg';
import name from '../../icon/menu/name.svg';
import star from '../../icon/menu/star.svg';

export const categoriesStatic = {
    'fine-dining': {
        description: 'Лучшие дорогие места',
        source: fineDiningIcon,
    },
    'smart-casual': {
        description: 'Достойные места на каждый день',
        source: smartCasual,
    },
    'city-cafe': {
        description: 'Городские кафе на каждый день',
        source: cityCafe,
    },
    bar: { description: 'Лучшие бары города', source: bars },
};

const _Menu = () => {
    // @ts-ignore
    const tg = window.Telegram.WebApp;
    const categories = useSelector((state) => state.categories);
    const navigate = useNavigate();

    const options = [
        {
            title: 'Поблизости',
            key: '1',
            source: pointer,
            link: '/places/near',
        },
        {
            title: 'Новые места',
            key: '2',
            source: newIcon,
            link: '/places/new',
        },
        {
            title: 'По названию',
            key: '3',
            source: name,
            link: '/places/search',
        },
        {
            title: 'Избранное',
            key: '4',
            source: star,
            link: '/places/wishlist',
        },
    ];

    React.useEffect(() => {
        const savedCity = localStorage.getItem('city');
        if (!savedCity) {
            navigate('/');
        }
        tg.expand();
    }, [])
    const resetCity = () => {
        localStorage.removeItem('city');
    };

    return (
        <div className="menu containers">
            <MenuHeader
                link="/"
                onClickHandler={resetCity}
                alt="back"
            />
            <div className="menu__title">
                <h1 className="title">Выберите категорию</h1>
                <h1 className="title">или способ поиска</h1>
            </div>
            <div className="menu-table">
                {categories.map((elem) => (
                    <Link
                        to={`/places/category/${elem.key}`}
                        key={elem.key}
                        className="menu-category"
                    >
                        <img src={categoriesStatic[elem.key].source} alt="menu-option" />
                        <div className="title s regular">{elem.title}</div>
                        <div className="text light grey">
                            {categoriesStatic[elem.key].description}
                        </div>
                    </Link>
                ))}
            </div>
            <Link to="/filters" className="menu-filter">
                <img src={settings} alt="settings" />
                <div className="menu-filter__title">
                    <p className="title s regular">
            Искать по тонким предпочтениям среди всех категорий
                    </p>
                </div>
            </Link>
            <Link to="/places/club" className="menu-club">
                <img src={aClub} alt="" />
            </Link>
            <div className="menu-table">
                {options.map((elem) => (
                    <Link key={elem.key} to={elem.link} className="menu-option">
                        <img src={elem.source} alt="menu-option" />
                        <div className="title s normal">{elem.title}</div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export const Menu = React.memo(_Menu);
