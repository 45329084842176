/* eslint-disable default-param-last */
const defaultState = {};

export const wishListReducer = (state = defaultState, action) => {
    const newState = { ...state };
    switch (action.type) {
    case 'initWishList':
        return action.payload;
    case 'addItemWishList':
        // eslint-disable-next-line no-case-declarations
        if (
            Object.prototype.hasOwnProperty.call(
                newState,
                action.payload.city,
            )
        ) {
            newState[action.payload.city].push(action.payload.placeId);
        } else {
            newState[action.payload.city] = [action.payload.placeId];
        }
        return { ...newState };
    case 'removeItemWishList':
        newState[action.payload.city] = state[action.payload.city].filter(
            (el) => el !== action.payload.placeId,
        );
        return { ...newState };
    default:
        return newState;
    }
};
