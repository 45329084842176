import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HeaderProps } from './Header';
import locationCity from '../../icon/menu/cityLocation.svg';
import { getCity } from '../../api/utils';


function MenuHeader({ link, onClickHandler }: HeaderProps) {
    const navigate = useNavigate();
    const [city, setCity] = React.useState<string>('');

    React.useEffect(() => {
        const chosenCity = getCity();
        if (chosenCity) {
            setCity(chosenCity.title);
        } else {
            navigate('/');
        }
    }, [])
    
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <nav className="header reverse">
            <Link
                to={link || ''}
                onClick={(event: React.MouseEvent) => {
                    if (!link) {
                        event?.preventDefault();
                    }
                    onClickHandler();
                    navigate(-1);
                }}
                className="header__navigate_menu gold"
            >
                <img src={locationCity} alt='location'/> <p className='title normal gold'>{city}</p>
            </Link>
        </nav>
    )
}


export default MenuHeader;