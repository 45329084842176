import React from 'react';
import './SearchInput.scss';
import searchIcon from '../../icon/menu/name.svg';

interface ISearchInputProps{
    value: string;
    // eslint-disable-next-line no-unused-vars
    setValue: (v: string) => void;
}

function SearchInput({ value, setValue }:ISearchInputProps) {
    const [underFocus, setFocusStatus] = React.useState(false);

    return (
        <div className="searchInput">
            <div className="searchInput__input-group">
                <div className="searchInput__input-group__icon">
                    <img src={searchIcon} alt="" />
                </div>
                <input
                    className={
                        underFocus
                            ? 'searchInput__input active'
                            : 'searchInput__input'
                    }
                    value={value}
                    onFocus={() => setFocusStatus(true)}
                    onBlur={() => setFocusStatus(false)}
                    placeholder="По названию"
                    onChange={(e) => setValue(e.target.value)}
                />
            </div>
            {underFocus || value ? (
                <button
                    type='button'
                    className="searchInput__cancel active"
                    onClick={() => setValue('')}
                >
                    <p className="gold">Отмена</p>
                </button>
            ) : (
                <div className="searchInput__cancel">
                    <p className="gold">Отмена</p>
                </div>
            )}
        </div>
    );
}

export default SearchInput;
