import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./PlacePreview.scss";
import WishListApi from "../../../api/wishList";
import star from "../../../icon/menu/star.svg";
import choosedStar from "../../../icon/menu/choosedStar.svg";
import { getCityID } from "../../../api/utils";
import { IPlace } from "../../../interfaces/interfaces";

interface PlacePreviewProps{
    place: IPlace;
    newTag: boolean;
    typeVerbose: boolean;
}


function PlacePreview({ place, newTag=false , typeVerbose=false }: PlacePreviewProps) {
    const wishlist = useSelector((state: any) => state.wishlist);
    // @ts-ignore
    const cityID: number = getCityID(); 

    return (
        <li>
            <Link
                key={place.id}
                className={typeVerbose? "placePreview type" : "placePreview"}
                to={`/places/${place.id}`}
            >
                <div className="placePreview__img">
                    <LazyLoadImage src={place?.static[0]} />
                </div>
                <div className="placePreview-content">
                    {typeVerbose && <aside className="text gold">{place.category.toLocaleUpperCase()}</aside>}
                    <div className="placePreview__title">
                        <h3 className="title">{place.title}</h3>
                        {newTag && <div className="text s gold">NEW</div>}
                    </div>
                    <div className="placePreview-content__descr">
                        <p className="text s normal">
                            {place.shortDescription}
                        </p>
                    </div>
                    <div className="placePreview-menu">
                        <div className="placePreview__btn">Подробнее</div>
                        <button
                            type="button"
                            id={place.id.toString()}
                            onClick={(event) => WishListApi.addWishListHandler(event)}
                        >
                            <img
                                id={place.id.toString()}
                                src={
                                    Object.prototype.hasOwnProperty.call(wishlist, cityID) &&
                                    wishlist[cityID].includes(place.id)
                                        ? choosedStar
                                        : star
                                }
                                className="placePreview-menu__img"
                                alt="star"
                            />
                        </button>

                    </div>
                </div>
            </Link>
        </li>
    );
}

export default PlacePreview;
