/* eslint-disable default-param-last */
const defaultState = [];

export const cityReducer = (state = defaultState, action) => {
    switch (action.type) {
    case 'initCity':
        return [...action.payload];
    default:
        return state;
    }
};
