import React, { useState, useEffect } from 'react';
import './PlaceDetailPage.scss';
import { useParams } from 'react-router-dom';
import PlacesApi from '../../../api/places';
import { getBanner } from '../../../api/api';
import PlaceDetail from '../../../components/Place/PlaceDetail/PlaceDetail';
import { IBanner, IPlaceDetail } from '../../../interfaces/interfaces';


function PlaceDetailPage() {
    const { id } = useParams();
    const [placeDetail, setPlaceDetail] = useState<IPlaceDetail | null>(null);
    const [banner, setBanner] = useState<IBanner | null>(null);
    const [loadingMessage, setLoadingMessage] = useState<string>('Загрузка...');

    useEffect(() => {
        getCafe();
        getAdv();
    }, []);

    async function getCafe() {
        if (id) {
            try {
                const result = await PlacesApi.getByPlaceId(+id);
                setPlaceDetail(result);
            } catch (error) {
                setLoadingMessage(
                    'Произошла ошибка. Попробуйте обновить страницу',
                );
            }
        }
    }
    const getAdv = async () => {
        const result = await getBanner();
        setBanner(result);
    };

    return (
        <div>
            {
                placeDetail && banner
                    ? <PlaceDetail placeDetail = { placeDetail } banner = { banner } />
                    :
                    <div className="placeDetailPage__loading">
                        <h1 className="title">{loadingMessage}</h1>
                    </div>
            }
        </div>
    );
}

export default PlaceDetailPage;
