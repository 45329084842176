/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore } from 'redux';
import { cityReducer } from './cityReducer';
import { filtersReducer, aClubReducer } from './filtersReducer';
import { wishListReducer } from './wishListReducer';
import { getCities, getFilters, getCategories } from '../api/api.ts';
import WishListApi from '../api/wishList.ts';
import { categoiesReducer } from './categoriesReducer';

const RootReducer = combineReducers({
    cities: cityReducer,
    filters: filtersReducer,
    categories: categoiesReducer,
    wishlist: wishListReducer,
    aClub: aClubReducer,
});

export const Store = createStore(
    RootReducer,
    window
        && window.__REDUX_DEVTOOLS_EXTENSION__
        && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export const initHandler = async () => {
    const cities = await getCities();
    const filters = await getFilters();
    const categories = await getCategories();
    const wishList = await WishListApi.getAll();
    Store.dispatch({ type: 'initCity', payload: [...cities] });
    Store.dispatch({ type: 'initFilters', payload: [...filters] });
    Store.dispatch({ type: 'initCategories', payload: [...categories] });
    Store.dispatch({ type: 'initWishList', payload: wishList });
};
