import { apiClient } from './client';

export const getFilters = async () => {
    const response = await apiClient.get('filters');
    return response.data;
};

export const getCities = async () => {
    const response = await apiClient.get('cities');
    return response.data;
};
export const getCategories = async () => {
    const response = await apiClient.get('categories');
    return response.data;
};
export const getBanner = async () => {
    const response = await apiClient.get('adv');
    return response.data;
};
