import { ICity } from "../interfaces/interfaces";

export const getCityID = (): number | null => (
    getCity()?.id || null
)

export const getCity = (): ICity|null => {
    const local = localStorage.getItem('city');
    if (local) {
        const city = JSON.parse(local);
        return city;
    }
    return null
}