import React from 'react';
import PlaceLayout from '../../components/Place/PlaceLayout/PlaceLayout';
import PlacesApi from '../../api/places';
import { IPlace } from '../../interfaces/interfaces';
import { searchMessageEnum } from '../../interfaces/enums';

function ClubPlacePage() {
    const [placeList, setPlaceList] = React.useState<IPlace[] | []>([]);
    const [searchMessage, setSearchMessage] = React.useState<string>(
        searchMessageEnum.PROCESS,
    );

    const getPlaces = async () => {
        const result = await PlacesApi.getAClubPlaces().catch(() => {
            checkPlaceStatus([]);
        });
        setPlaceList(result);
        checkPlaceStatus(result);
    };

    React.useEffect(() => {
        getPlaces();
    }, []);

    const checkPlaceStatus = (result: IPlace[] | []) => {
        if (result && result.length > 0) {
            setSearchMessage(searchMessageEnum.PROCESS);
        } else {
            setSearchMessage(searchMessageEnum.ERORR);
        }
    };

    return (
        <PlaceLayout
            title={<h1 className="title">Выбор Альфа-клуба</h1>}
            placeList={placeList}
            searchMessage={searchMessage}
        />
    );
}

export default ClubPlacePage;
