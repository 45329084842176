import React, { useEffect } from 'react';
import './place.scss';
import { Link } from 'react-router-dom';
import stringSimilarity from 'string-similarity';
import SearchInput from '../../components/SearchInput/SearchInput';
import PlaceList from '../../components/Place/PlaceList/PlaceList';

import PlacesApi from '../../api/places';

function PlaceSearchPage() {
    const [searchMessage, setSearchMessage] = React.useState(
        'Введите поисковый запрос',
    );
    const [searchValue, setSearchValue] = React.useState('');
    const [placeStore, setPlacesStore] = React.useState([]);
    const [comparedPlaces, setComparedPlaces] = React.useState([]);

    async function getCafes() {
        const result = await PlacesApi.getPlacesByFilters();
        setPlacesStore(result);
    }

    function filterPlaces() {
        if (placeStore.length > 0 && searchValue) {
            const titles = placeStore.map((place) => place.title.toLowerCase());
            const searchResult = stringSimilarity.findBestMatch(
                searchValue.toLowerCase(),
                [...titles],
            );
            const compareResults = searchResult.ratings;
            const filteredPlaces = placeStore.filter(
                (elem, index) => compareResults[index].rating > 0.2,
            );
            if (filteredPlaces.length > 0) {
                setComparedPlaces(filteredPlaces);
            } else {
                setSearchMessage('По вашему запросу ничего не найдено');
            }
        } else {
            setSearchMessage('Введите поисковый запрос');
            setComparedPlaces([]);
        }
    }

    useEffect(() => {
        getCafes();
    }, []);

    useEffect(() => {
        filterPlaces();
    }, [searchValue]);

    return (
        <div className="place">
            <div className="place__wrapper containers">
                <div className="placeSearch__header">
                    <div className="place__back">
                        <Link to="/menu"> Назад </Link>
                    </div>
                </div>
                <SearchInput value={searchValue} setValue={setSearchValue} />
                <PlaceList
                    listData={comparedPlaces}
                    searchMessage={searchMessage}
                />
            </div>
        </div>
    );
}

export default PlaceSearchPage;
