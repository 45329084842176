import { AxiosInstance } from 'axios';
import { apiClient } from './client';
// eslint-disable-next-line import/no-cycle
import { Store } from '../store';
import { getCityID } from './utils';

export default class WishListApi {
    static client: AxiosInstance = apiClient;

    static prefix: string = 'wishlist';

    static getAll = async () => {
        try {
            const response = await this.client.get(this.prefix);
            return response.data;
        } catch (error) {
        }
        return {};
    };

    static addItem = async (placeId: number) => {
        const cityId = getCityID();
        Store.dispatch({
            type: 'addItemWishList',
            payload: { city: cityId, placeId: +placeId },
        });
        try {
            const response = await this.client.post(this.prefix, {
                place_id: placeId,
                city_id: getCityID(),
            });
            return response.data;
        } catch (error) {
            Store.dispatch({
                type: 'removeItemWishList',
                payload: { city: cityId, placeId: +placeId },
            });
        }
        return {};
    };

    static removeItem = async (placeId: number) => {
        const cityId = getCityID();
        try {
            await this.client.delete(`${this.prefix}/${cityId}/${placeId}`);
        } catch (error: any) {
            throw Error(error);
        }
        Store.dispatch({
            type: 'removeItemWishList',
            payload: { city: cityId, placeId: +placeId },
        });
    };

    static addWishListHandler = async (event: any) => {
        event.preventDefault();
        const { wishlist } = Store.getState();
        const city = getCityID();
        const { target } = event;
        const value: number = +target.id;
        if (
            city
            && Object.prototype.hasOwnProperty.call(wishlist, city)
            && wishlist[city].includes(value)
        ) {
            await this.removeItem(value);
        } else if (city) {
            await this.addItem(value);
        }
    };
}
