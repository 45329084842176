import React from 'react';
import { useSelector } from 'react-redux';
import PlaceLayout from '../../components/Place/PlaceLayout/PlaceLayout';
import PlacesApi from '../../api/places';
import { IPlace } from '../../interfaces/interfaces';
import { searchMessageEnum } from '../../interfaces/enums';
import { getCityID } from '../../api/utils';

function WishList() {
    const wishlist = useSelector((state: any) => state.wishlist);
    const [placeList, setPlaceList] = React.useState<IPlace[] | []>([]);
    const [searchMessage, setSearchMessage] = React.useState('Загрузка...');

    const getWishList = async () => {
        const cityID: number | null = getCityID();
        if (cityID) {
            if (Object.prototype.hasOwnProperty.call(wishlist, cityID)) {
                const cityWishList = [...wishlist[cityID]];
                const places: IPlace[] | any = await Promise.all(
                    cityWishList.map((elem) => PlacesApi.getByPlaceId(elem)),
                ).catch(() => {
                    checkPlaceStatus([]);
                });
                setPlaceList(places);
                checkPlaceStatus(places);
            } else {
                checkPlaceStatus([]);
            }
        }
    };
    const checkPlaceStatus = (result: IPlace[] | []) => {
        if (result && result.length > 0) {
            setSearchMessage(searchMessageEnum.PROCESS);
        } else {
            setSearchMessage(searchMessageEnum.EMPTY);
        }
    };

    React.useEffect(() => {
        getWishList();
    }, []);

    React.useEffect(() => {
        getWishList();
    }, [wishlist]);

    return (
        <PlaceLayout
            title={<h1 className="title">Избранное</h1>}
            placeList={placeList}
            searchMessage={searchMessage}
            typeVerbose
        />
    );
}

export default WishList;
