import React, { useState } from 'react';
import './PlaceDetail.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { IBanner, IPlaceDetail, IQuestion } from '../../../interfaces/interfaces';
import PlacesApi from '../../../api/places';
import WishListApi from '../../../api/wishList';
import star from '../../../icon/menu/star.svg';
import choosedStar from '../../../icon/menu/choosedStar.svg';
import LocalIcon from '../../../icon/catalogDetail/local.svg';
import PlusIcon from '../../../icon/catalogDetail/plus.svg';
import ShareIcon from '../../../icon/catalogDetail/share.svg';
import PhoneIcon from '../../../icon/catalogDetail/phone.svg';
import ArrowIcon from '../../../icon/catalogDetail/arrow.svg';
import aClub from '../../../icon/catalogDetail/aclub.svg';
import { getCityID } from '../../../api/utils';

interface IPlaceDetailProps{
    placeDetail: IPlaceDetail;
    banner?: IBanner | null;
}

function PlaceDetail({ placeDetail, banner }: IPlaceDetailProps) {
    const wishlist: any = useSelector((state: any) => state.wishlist);
    // @ts-ignore
    const tg: any = window.Telegram.WebApp;
    // @ts-ignore
    const cityID: string = getCityID();
    const [disableStatus, setDisableStatus] = useState<boolean>(false);
    const navigator = useNavigate();

    const callPhoneHandler = () => {
        if (tg.initData) {
            const callPhone = () => {
                tg.openLink(
                    `${window.location.origin}/call?phone=${placeDetail.contactInfo.phone}`,
                );
            };
            callPhone();
        } else {
            window.open(`tel:${placeDetail.contactInfo.phone}`);
        }
    };
    const openLink = (event: any, link: string) => {
        if (tg.initData) {
            event.preventDefault();
            tg.openLink(link);
        } else {
            window.open(link);
        }
    };

    const sharePlaceTg = async () => {
        setDisableStatus(true);
        try {
            await PlacesApi.shareTG(+placeDetail.id);
            tg.close();
            setDisableStatus(false);
        } catch (error) {
            setDisableStatus(false);
        }
    };

    const changeAccordion = (e: any) => {
        if (e.target.classList.contains('a')) {
            e.target.classList.remove('a');
        } else {
            e.target.classList.add('a');
        }
    };

    function accordionRender() {
        return (
            <ul className="catalogDetail__accordions">
                {
                    placeDetail.question.map((question: IQuestion) => (
                        <li
                            className='catalogDetail__accordion'

                        >
                            {question.text ?
                                <div
                                    role='button'
                                    tabIndex={0}
                                    className="catalogDetail__accordionElem a"
                                    onClick={changeAccordion}
                                >
                                    <div className="catalogDetail__accordionHeader">
                                        <h5 className="catalogDetail__accordionTitle">
                                            {question.title}
                                        </h5>
                                        <div className="catalogDetail__accordion-icon">
                                            <img src={PlusIcon} alt="icon" />
                                        </div>
                                    </div>
                                    <p
                                        className="catalogDetail__accordion-content"
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: question.text,
                                        }}
                                    />
                                </div>
                                :
                                null
                            }
                        </li>
                    ))}
            </ul>
        );
    }

    return (
        <div className="catalogDetail">
            <div className="catalogDetail__wrapper">
                <div
                    role="link"
                    tabIndex={0}
                    className="catalogDetail__backs"
                    onClick={() => navigator(-1)}
                >
                    <p className="gold">Назад</p>
                </div>
                <div
                    role='button'
                    tabIndex={0}
                    id={`${placeDetail.id}`}
                    onClick={(e) => WishListApi.addWishListHandler(e)}
                >
                    <img
                        // id={`${placeDetail.id}`}
                        // onClick={(e) => WishListApi.addWishListHandler(e)}
                        className="catalogDetail__wish"
                        src={
                            Object.prototype.hasOwnProperty.call(wishlist, cityID)
                            && wishlist[cityID].includes(placeDetail.id)
                                ? choosedStar
                                : star
                        }
                        alt="star"
                    />
                </div>
                <div className="catalogDetail__slider">
                    <div className="catalogDetail__header">
                        <div className="catalogDetail__header-content">
                            {placeDetail.a_club && <img src={aClub}  alt="a-club"/>}
                            <h1 className="catalogDetail__title">
                                {placeDetail.title}
                            </h1>
                            <div className="catalogDetail__categ">
                                {placeDetail.category}
                            </div>
                            <p className="catalogDetail__description">
                                {placeDetail.description[0]}
                            </p>
                        </div>
                    </div>
                    <Swiper
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        preloadImages
                        speed={1240}
                        pagination
                        modules={[Pagination, Autoplay]}
                    >
                        {placeDetail.static.map((el) => (
                            <SwiperSlide key={el}>
                                <LazyLoadImage src={el} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="catalogDetail__wrap catalogDetail__containers">
                    <p className="catalogDetail__desc">
                        {placeDetail.description[1]}
                    </p>
                    {accordionRender()}
                    <div className="catalogDetail__info">
                        <div className="catalogDetail__info-address">
                            <img src={LocalIcon} alt="local" />
                            <address>{placeDetail.contactInfo.address}</address>
                        </div>
                        {placeDetail.contactInfo.mapLink ? (
                            <a
                                className="catalogDetail__info-link"
                                href={placeDetail.contactInfo.mapLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p> Проложить маршрут на карте </p>
                                <img src={ArrowIcon} alt="arrow" />
                            </a>
                        ) : (
                            <div className="catalogDetail__info-link">
                                <p>Нет маршрута</p>
                            </div>
                        )}
                        {placeDetail.contactInfo.url ? (
                            <button
                                type='button'
                                className="catalogDetail__info-website"
                                onClick={(event) => {
                                    openLink(
                                        event,
                                        placeDetail.contactInfo.url,
                                    );
                                }}
                            >
                                {placeDetail.contactInfo.url}
                            </button>
                        ) : (
                            ''
                        )}
                        <div className="catalogDetail__info-phone">
                            <img src={PhoneIcon} alt="arrow" />
                            <p>{placeDetail.contactInfo.phone}</p>
                        </div>
                    </div>
                </div>
                {banner && (
                    <div className="catalogDetail__banner catalogDetail__containers">
                        <LazyLoadImage
                            src={(banner as IBanner).static}
                            onClick={(e) => openLink(e, (banner as IBanner).url)}
                        />
                    </div>
                )}
                <div className="catalogDetail__btns catalogDetail__containers">
                    <button
                        type='button'
                        className="catalogDetail__call"
                        onClick={() => callPhoneHandler()}
                    >
            Позвонить
                    </button>
                    <button
                        type='button'
                        className="catalogDetail__share"
                        disabled={disableStatus}
                        onClick={() => {
                            sharePlaceTg();
                        }}
                    >
                        <p>Поделиться</p>
                        <img src={ShareIcon} alt="share" />
                        <script
                            async
                            src="https://telegram.org/js/telegram-widget.js?19"
                            data-telegram-share-url="https://core.telegram.org/widgets/share"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PlaceDetail;
