import React from 'react';
import './PlaceLayout.scss';
import Header from '../../Header/Header';
import PlaceList from '../PlaceList/PlaceList';
import arrowUpIcon from '../../../icon/catalog/arrowUp.svg';
import { IPlace } from '../../../interfaces/interfaces';

interface IProps {
    title: React.ReactElement;
    placeList: IPlace[];
    searchMessage: string;
    navBar?: React.ReactElement;
    newTag?: boolean;
    typeVerbose?: boolean;
    onClickEvent?: () => void;
}

function PlaceLayout({
    title,
    placeList,
    searchMessage,
    navBar,
    newTag = false,
    typeVerbose = false,
    onClickEvent,
}: IProps) {
    const [arrowStatus, setArrowStatus] = React.useState<boolean>(false);

    const liftUpHandler = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const checkScrollStatus = () => {
        if (window.pageYOffset > 400) {
            setArrowStatus(true);
        } else if (window.pageYOffset <= 400) {
            setArrowStatus(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('scroll', checkScrollStatus);
    }, []);

    return (
        <div className="place">
            <div className="place__wrapper containers">
                <Header
                    title="Назад"
                    onClickHandler={
                        onClickEvent
                            ? () => {
                                onClickEvent();
                            }
                            : () => 0
                    }
                />
                <div className="placeLayout__sub-header">
                    <div className="placeLayout__title">
                        {title}
                        {navBar}
                    </div>
                </div>
                <PlaceList
                    placeList={placeList}
                    searchMessage={searchMessage}
                    newTag={newTag}
                    typeVerbose={typeVerbose}
                />
            </div>
            <button
                type='button'
                className={
                    arrowStatus
                        ? 'placeLayout__arrow'
                        : 'placeLayout__arrow disable'
                }
                onClick={() => liftUpHandler()}
            >
                <img src={arrowUpIcon} alt='arrowUp'/>
            </button>
        </div>
    );
}

export default PlaceLayout;
