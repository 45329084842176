import React, { useEffect, useRef } from 'react';
import './filters.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import { IFilter } from '../../interfaces/interfaces';

// set city and filters to url params in Link dont use props
const _Filters = () => {
    // @ts-ignore
    const tg = window.Telegram.WebApp;
    const mainButton = tg.MainButton;
    const navigator = useNavigate();
    const buttonRef = useRef();
    const filters: IFilter[] = useSelector((state: any) => state.filters);
    const dispatch = useDispatch();

    useEffect(() => {
        initTelegramButton();
        createButtons();
    }, []);

    const initTelegramButton = () => {
        mainButton.setParams({
            text: 'Выбери за меня',
            color: '#2f3337',
            text_color: '#cfb28b',
        });
        mainButton.onClick(() => {
            randomCheckboxHandler();
        });
        mainButton.show();
    };

    function createButtons() {
        let hiddenState = 'none';
        filters.map((element: IFilter) => {
            if (element.checked) {
                hiddenState = 'flex';
                return true;
            }
            return false;
        });
        if (buttonRef && buttonRef.current) {
            (buttonRef as any).current.style.display = hiddenState;
        }
    }

    const randomCheckboxHandler = () => {
        dispatch({ type: 'resetFilters', payload: {} });
        const { length } = filters;
        const iters = Math.floor(Math.random() * 3 + 1);
        for (let i = 0; i < iters; i+=1) {
            const placeId = getRandomInt(length);
            dispatch({ type: 'chooseFilters', payload: { id: placeId } });
        }
        mainButton.hide();
        navigator('/places');
    };

    function getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }

    function chooseFilter(elId: number) {
        dispatch({ type: 'chooseFilters', payload: { id: elId } });
        createButtons();
    }

    return (
        <div className="selection containers">
            <div className="selection__wrapper">
                <Header
                    link="/menu"
                    title="Назад"
                    onClickHandler={() => {
                        mainButton.hide();
                    }}
                />
                <h1 className="selection__title">
          Укажите свои предпочтения или планы,
                    {' '}
                    <br />
                    {' '}
          и я подберу
          идеальное место
                </h1>
                <ul className="selection__list">
                    {filters
                        && filters?.map((el) => (
                            <li className="selection__item" key={el.id}>
                                <label
                                    className={`selection__label ${
                                        el.checked ? 'selection__a' : ''
                                    }`}
                                    htmlFor={`customCheck-${el.id}`}
                                >
                                    <input
                                        type="checkbox"
                                        className="selection__input"
                                        id={`customCheck-${el.id}`}
                                        checked={el.checked}
                                        onChange={() => chooseFilter(el.id)}
                                    />
                                    <p>{el.title}</p>
                                </label>
                            </li>
                        ))}
                </ul>
            </div>
            <div className="selection__test">
                {/* @ts-ignore */}
                <div ref={buttonRef} className="selection__btns">
                    {/* vvv Прокинуть параметры запроса в эту ссылку (?city={city}&[filters=true]) vvv */}
                    <div className="selection__back" />
                    <Link
                        to="/places"
                        className="selection__ready"
                        onClick={() => {
                            mainButton.hide();
                        }}
                    >
            Применить фильтр
                    </Link>
                    {/* <button className='selection__random' onClick={randomCheckboxHandler}>
                            Выберите за меня
                        </button> */}
                </div>
            </div>
        </div>
    );
};

export const Filters = React.memo(_Filters);
