import React from 'react';
import './PlaceList.scss';
import PlacePreview from '../PlacePreview/PlacePreview';
import { IPlace } from '../../../interfaces/interfaces';

interface IPlaceListProps{
    placeList: IPlace[];
    searchMessage: string;
    newTag: boolean;
    typeVerbose?: boolean;
}

function PlaceList({ placeList, searchMessage, newTag=false, typeVerbose=false }:IPlaceListProps) {
    return (
        <ul className="placeList">
            {placeList && placeList.length > 0 ? (
                placeList.map((el) => (
                    <PlacePreview key={el.id} place={el} newTag={newTag} typeVerbose={typeVerbose} />
                ))
            ) : (
                <div className="error__text">
                    <h5 className="title">{searchMessage}</h5>
                </div>
            )}
        </ul>
    );
}

export default PlaceList;
