import React, { useState, useEffect } from 'react';
import './CallPage.scss';
import { useSearchParams } from 'react-router-dom';

const _AndroidPage = () => {
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [phone, setPhone] = useState();
    const [phoneValue, setPhoneValue] = useState();

    useEffect(() => {
        const phoneNum = searchParams.get('phone');
        setPhone(phoneNum.replace(/^\s+|\s+$/g, ''));
        setPhoneValue(phoneNum.replace(/\s+|-|\(|\)|\s+$|/g, ''));
    }, []);

    return (
        <div className="callPage containers">
            <h1 className="title">
        Звонок по телефону
                {phone}
            </h1>
            <a href={`tel:+${phoneValue}`} className="callPage__button">
        Позвонить
            </a>
        </div>
    );
};

export const Android = React.memo(_AndroidPage);
