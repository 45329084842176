import React from 'react';
import PlacesApi from '../../api/places';
import PlaceLayout from '../../components/Place/PlaceLayout/PlaceLayout';
import { searchMessageEnum } from '../../interfaces/enums';
import { ICoords, IPlace } from '../../interfaces/interfaces';


function NearPlacesPage() {
    const [placeList, setPlaceList] = React.useState<IPlace[] | []>([]);
    const [searchMessage, setSearchMessage] = React.useState<string>(
        searchMessageEnum.PROCESS,
    );
    const [coords, setCoords] = React.useState<ICoords | undefined>(undefined);

    async function getCafes() {
        const result = await PlacesApi.getPlacesByFilters([], coords).catch(
            () => checkPlaceStatus([]),
        );
        setPlaceList(result);
        checkPlaceStatus(result);
    }

    const checkPlaceStatus = (result: IPlace[] | []) => {
        if (result && result.length > 0) {
            setSearchMessage(searchMessageEnum.PROCESS);
        } else {
            setSearchMessage(searchMessageEnum.ERORR);
        }
    };

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(showPosition, () => {
            setSearchMessage(searchMessageEnum.NO_COORDS);
        });
    }, []);

    React.useEffect(() => {
        if (coords && coords.latitude && coords.longitude) {
            getCafes();
        } else if (coords && !(coords.latitude && coords.longitude)) {
            setSearchMessage(searchMessageEnum.NO_COORDS);
        }
    }, [coords]);

    function showPosition(position: any) {
        const { latitude } = position.coords;
        const { longitude } = position.coords;
        setCoords({ latitude, longitude });
    }

    return (
        <PlaceLayout
            title={<h1 className="title">Рестораны возле вас</h1>}
            placeList={placeList}
            searchMessage={searchMessage}
        />
    );
}

export default NearPlacesPage;
