import { AxiosInstance } from 'axios';
import { IFilter, ICoords } from '../interfaces/interfaces';
import { Store } from '../store';
import { apiClient } from './client';
import { getCityID } from './utils';

export default class PlacesApi {
    static client: AxiosInstance = apiClient;

    static prefix = 'places';

    static getPlacesByFilters = async (
        filters?: IFilter[],
        coords?: ICoords,
    ) => {
        let filtersParam = '';
        if (filters) {
            const checkedFilters = filters.filter((elem) => elem.checked);
            filtersParam = checkedFilters.map((elem) => elem.key).join('Z');
        }
        const queryParams: any = {
            city: getCityID(),
            latitude: coords && coords.latitude ? coords.latitude : '',
            longitude: coords && coords.longitude ? coords.longitude : '',
            filters: filters ? filtersParam : '',
        };
        const urlParams = new URLSearchParams(queryParams).toString();
        const url = `${this.prefix}?${urlParams}`;
        const response = await this.client.get(url);
        return response.data;
    };

    static getAClubPlaces = async () => {
        const store = Store.getState();
        const queryParams: any = {
            city: getCityID(),
            filters: [store.aClub],
        };
        const urlParams = new URLSearchParams(queryParams).toString();
        const url = `${this.prefix}?${urlParams}`;
        const response = await this.client.get(url);
        return response.data;
    };

    static getCategoryPlaces = async (category: string) => {
        const queryParams: any = {
            city: getCityID(),
            categories: category,
        };
        const urlParams = new URLSearchParams(queryParams).toString();
        const url = `${this.prefix}?${urlParams}`;
        const response = await this.client.get(url);
        return response.data;
    };

    static getNewPlaces = async () => {
        const queryParams: any = {
            city: getCityID(),
        };
        const urlParams = new URLSearchParams(queryParams).toString();
        const response = await this.client.get(
            `${this.prefix}/new?${urlParams}`,
        );
        return response.data;
    };

    static getByPlaceId = async (placeId: number) => {
        const queryParams: any = {
            id: placeId,
            city: getCityID(),
        };
        const urlParams = new URLSearchParams(queryParams).toString();
        const url = `${this.prefix}/place?${urlParams}`;
        const response = await this.client.get(url);
        return response.data;
    };

    static shareTG = async (placeId: number) => {
        const city = getCityID();
        const response = await this.client.post(
            `${this.prefix}/${city}/${placeId}/share`,
            {},
        );
        return response.data;
    };
}
