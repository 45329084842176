import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlacesApi from '../../api/places';
import PlaceLayout from '../../components/Place/PlaceLayout/PlaceLayout';
import { IPlace } from '../../interfaces/interfaces';
import { searchMessageEnum } from '../../interfaces/enums';

const _PlaceCategoryPage = () => {
    const categories = useSelector((state: any) => state.categories);
    const { categoryKey } = useParams();
    const [placeList, setPlaceList] = React.useState<IPlace[] | []>([]);
    const [searchMessage, setSearchMessage] = React.useState<string>(
        searchMessageEnum.PROCESS,
    );

    const getPlaces = async () => {
        if (categoryKey) {
            const response = await PlacesApi.getCategoryPlaces(
                categoryKey,
            ).catch(() => {
                checkPlaceStatus([]);
            });
            setPlaceList(response);
            checkPlaceStatus(response);
        } else {
            checkPlaceStatus([]);
        }
    };
    const checkPlaceStatus = (result: IPlace[] | []) => {
        if (result && result.length > 0) {
            setSearchMessage(searchMessageEnum.PROCESS);
        } else {
            setSearchMessage(searchMessageEnum.ERORR);
        }
    };

    React.useEffect(() => {
        getPlaces();
    }, []);

    React.useEffect(() => {
        getPlaces();
    }, [categoryKey]);

    const getCategoryTitle = () => {
        const titles = categories.filter((el: any) => el.key === categoryKey);
        if (titles.length > 0) {
            return titles[0].title;
        }
        return '';
    };

    return (
        <PlaceLayout
            title={(
                <div className="placeCategory__title">
                    <h1 className="title normal">Рестораны категории</h1>
                    <h2 className="title normal">{getCategoryTitle()}</h2>
                </div>
            )}
            placeList={placeList}
            searchMessage={searchMessage}
        />
    );
};
const PlaceCategoryPage = React.memo(_PlaceCategoryPage);
export default PlaceCategoryPage;
