import React from 'react';
import PlaceLayout from '../../components/Place/PlaceLayout/PlaceLayout';
import PlacesApi from '../../api/places';
import { searchMessageEnum } from '../../interfaces/enums';
import { IPlace } from '../../interfaces/interfaces';

function NewPlacesPage() {
    const [placeList, setPlaceList] = React.useState([]);
    const [searchMessage, setSearchMessage] = React.useState('Поиск...');

    const getNewPlacesHandler = async () => {
        const places = await PlacesApi.getNewPlaces().catch(() => checkPlaceStatus([]));
        setPlaceList(places);
        checkPlaceStatus(places);
    };

    const checkPlaceStatus = (result: IPlace[] | []) => {
        if (result && result.length > 0) {
            setSearchMessage(searchMessageEnum.PROCESS);
        } else {
            setSearchMessage(searchMessageEnum.ERORR);
        }
    };

    React.useEffect(() => {
        getNewPlacesHandler();
    }, []);

    return (
        <PlaceLayout
            title={<h1 className="title">Рестораны NEW</h1>}
            placeList={placeList}
            searchMessage={searchMessage}
            newTag
        />
    );
}

export default NewPlacesPage;
