import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.scss';

export interface HeaderProps {
    title: string;
    link?: string;
    onClickHandler: () => void;
}

const _Header = ({ title, link, onClickHandler }: HeaderProps) => {
    const navigate = useNavigate();

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <nav className="header">
            <Link
                to={link || ''}
                onClick={(event: React.MouseEvent) => {
                    if (!link) {
                        event?.preventDefault();
                    }
                    onClickHandler();
                    navigate(-1);
                }}
                className="header__navigate gold"
            >
                {title}
            </Link>
        </nav>
    );
};

const Header = React.memo(_Header);
export default Header;
