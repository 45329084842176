import React, { useEffect, useState } from 'react';
import './place.scss';
import { useSelector } from 'react-redux';
import FiltersNav from '../../components/Filters/FiltersNav';
import PlacesApi from '../../api/places';
import PlaceLayout from '../../components/Place/PlaceLayout/PlaceLayout';
import { searchMessageEnum } from '../../interfaces/enums';

const _PlaceListPage = () => {
    const filters = useSelector((state) => state.filters);
    const [places, setPlaces] = useState([]);
    const [coords, setCoords] = useState({ latitude: null, longitude: null });
    const [searchMessage, setSearchMessage] = useState('Поиск...');
    const [checkboxActive, setCheckboxActive] = useState(false);

    const checkPlaceStatus = (result) => {
        if (result && result.length > 0) {
            setSearchMessage(searchMessageEnum.PROCESS);
        } else {
            setSearchMessage(searchMessageEnum.ERORR);
        }
    };

    async function getCafes() {
        const response = await PlacesApi.getPlacesByFilters(
            filters,
            coords,
        ).catch(() => checkPlaceStatus([]));
        setPlaces(response);
        checkPlaceStatus(response);
    }

    useEffect(() => {
        getCafes();
    }, []);

    function showPosition(position) {
        const { latitude } = position.coords;
        const { longitude } = position.coords;
        setCoords({ latitude, longitude });
    }

    const checkPosition = () => {
        setCheckboxActive(!checkboxActive);
        navigator.geolocation.getCurrentPosition(showPosition);
    };

    useEffect(() => {
        getCafes();
    }, [coords]);

    useEffect(() => {
        getCafes();
    }, [filters]);

    return (
        <PlaceLayout
            title={<h1 className="title semiBold">Рестораны для вас</h1>}
            placeList={places}
            searchMessage={searchMessage}
            navBar={(
                <>
                    <FiltersNav />
                    <div className="place__checkbox">
                        <label
                            className={`place__checkbox-label 
                            ${checkboxActive ? 'place__checkbox-a' : ''}`}
                            htmlFor="placeCheckbox"
                        >
                            <input
                                type="checkbox"
                                className="place__checkbox-input"
                                id="placeCheckbox"
                                checked={checkboxActive}
                                onChange={checkPosition}
                            />
                            <p>Показать только ближайшие места</p>
                        </label>
                    </div>
                </>
            )}
        />
    );
};

export const Places = React.memo(_PlaceListPage);
