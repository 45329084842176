import React from 'react';
import './FiltersNav.scss';
import { useSelector, useDispatch } from 'react-redux';

function FiltersNav() {
    const filters = useSelector((state) => state.filters);
    const dispatch = useDispatch();
    const ulRef = React.useRef();
    const [filterList, setFilterList] = React.useState(filters);

    const sortFilters = () => {
        ulRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        const checkedFilters = filters.filter((elem) => elem.checked);
        const notCheckedFilters = filters.filter((elem) => !elem.checked);
        setFilterList([...checkedFilters, ...notCheckedFilters]);
    };

    React.useEffect(() => {
        sortFilters();
    }, []);

    React.useEffect(() => {
        sortFilters();
    }, [filters]);

    const filterHandler = (event, elId) => {
        event.preventDefault();
        dispatch({ type: 'chooseFilters', payload: { id: elId } });
    };

    return (
        <ul ref={ulRef} className="catalog__nav">
            {filterList.map((el) => (
                <li>
                    <button
                        type="button"
                        key={el.id}
                        className={`catalog__nav-item ${
                            el.checked
                                ? 'catalog__label-active'
                                : 'catalog__label-deactive'
                        }`}
                        onClick={(event) => filterHandler(event, el.id)}
                    >
                        <label className="catalog__label" htmlFor={el.id}>
                            <input
                                type="checkbox"
                                className="catalog__input"
                                id={el.id}
                                checked={el.checked}
                            />
                            <p>{el.title}</p>
                        </label>
                    </button>
                </li>
            ))}
        </ul>
    );
}

export default FiltersNav;
