import axios from 'axios';

export const apiClient = axios.create({
    baseURL: '/api/webapp/',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});
