const defaultState = [];

// action = {type: string, payload: any}
export const filtersReducer = (state = defaultState, action) => {
    const resetFilters = () => {
        const filters = state.map((elem) => {
            elem.checked = false;
            return elem;
        });
        return [...filters];
    };
    const chooseFilters = () => {
        const filters = state.map((elem) => {
            if (elem.id === +action.payload.id) {
                elem.checked = !elem.checked;
            }
            return elem;
        });
        return [...filters];
    };
    switch (action.type) {
    case 'initFilters':
        return [...action.payload];
    case 'chooseFilters':
        return chooseFilters();
    case 'resetFilters':
        return resetFilters();
    default:
        return state;
    }
};

const aClubDefaultState = 'f_al';

export const aClubReducer = (state = aClubDefaultState, action) => {
    switch (action.type) {
    default:
        return state;
    }
};
